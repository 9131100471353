export const PHI = (Math.sqrt(5) + 1) / 2 - 1 // golden ratio
export const GOLDEN_ANGLE = PHI * Math.PI * 2

export const fric = 0.9 // drag or air friction
export const surF = 0.71 // ground and box friction
export const grav = 0.0 // gravity
export const STIFFNESS = 10 // number of itterations for line constraint

export const BRISTLE_COUNT = 120
export const BRISTLE_SEGMENT_COUNT = 6

