import { movePoint, constrainLine } from './../helpers'
import { BRISTLE_SEGMENT_COUNT, BRISTLE_COUNT } from './../settings'

export class Vec2 {
  constructor(x, y) {
    this.x = x
    this.y = y
  }
}

export class Line {
  constructor(p1, p2) {
    this.p1 = p1
    this.p2 = p2
    this.len = Math.hypot(p1.x - p2.x, p1.y - p2.y)
  }
}

export class Bristle {
  constructor(offset, distanceToCenter, brushRadius) {
    this.points = []
    this.lines = []

    this.offset = offset

    this.distanceToCenter = distanceToCenter

    // this.resistance = 1.1 + (distanceToCenter)
    // this.friction = 0.6 + ((1 - distanceToCenter) * 0.4)

  //  this.resistance = 1.1 + (distanceToCenter) * 1.3
    // this.resistance = 1.0 + ((1 - distanceToCenter) * 1)
    this.resistance = 7
    this.friction = 0.90

    const angle = distanceToCenter * (2*Math.PI)
    this.gravity = {
      x: offset.x / 900,
      y: offset.y / 900
    }

    this.gravity = {
      x: 0,
      y: 0
    }

  }

  createBristle () {
    const segments = BRISTLE_SEGMENT_COUNT
    this.addAnchor(500 + this.offset.x, 500 + this.offset.y)

    for (let i = 0; i < segments; i++) {
      let length = (this.distanceToCenter * 8) + 7
      // let length = 20 * (1 - distanceToCenter)
      this.addChainLink(length)
    }
  }

  createSmearBristle() {
    const anchor1 = this.addPoint(0, 0, 0, 0, 0, true)
    const anchor2 = this.addPoint(0, 10, 0, 0, 0)
    const anchor3 = this.addPoint(0, 20, 0, 0, 0)

    const p1 = this.addPoint(0, 30, 0, 100, 50)

    const p2a = this.addPoint(30, 100, 0, 100, 50)
    const p2b = this.addPoint(-30, 100, 0, 100, 50)

    const p3a = this.addPoint(50, 200, 0, 100, 50)
    const p3b = this.addPoint(-50, 200, 0, 100, 50)

    this.addLine(anchor1, anchor2)
    this.addLine(anchor2, anchor3)

    this.addLine(anchor3, p1)

    this.addLine(p1, p2a)
    this.addLine(p2a, p2b)

    this.addLine(p1, p3a)
    this.addLine(p3a, p3b)

    this.addLine(p2b, p3b)

    this.resistance = 1.75
    this.friction = 0.96
  }

  addPoint(x, y, vx, vy, rad = 40, fixed = false) {
    this.points.push({
      x: x,
      y: y,
      ox: x - vx,
      oy: y - vy,
      fixed: fixed,
      radius: rad
    })
    return this.points[this.points.length - 1]
  }

  addLine(p1, p2) {
    const line = new Line(p1, p2)
    this.lines.push(line)
    return line
  }

  movePoints() {
    for (var i = 0; i < this.points.length; i++) {
      movePoint(this.points[i], this.friction, this.gravity)
    }
  }

  constrainLines() {
    for (var i = 0; i < this.lines.length; i++) {
      constrainLine(this.lines[i], this.resistance)
    }
  }

  drawLines(position, smearPosition, w, h, bristleIndex) {
    const bristleOffset = bristleIndex * BRISTLE_SEGMENT_COUNT * 4

    for (let i = 0; i < this.lines.length; i++) {
      const posIndex = (i * 4) + bristleOffset
      const smearIndex = (i * 4) + (bristleIndex * (((BRISTLE_SEGMENT_COUNT + 1) * 2)) * 2)

      position[posIndex + 0] = this.lines[i].p1.x / w
      position[posIndex + 1] = 1 - this.lines[i].p1.y / h
      position[posIndex + 2] = this.lines[i].p2.x / w
      position[posIndex + 3] = 1 - this.lines[i].p2.y / h

      smearPosition[posIndex + 0] = this.lines[i].p2.x / w
      smearPosition[posIndex + 1] = 1 - this.lines[i].p2.y / h
      smearPosition[posIndex + 2] = this.lines[i].p2.ox / w
      smearPosition[posIndex + 3] = 1 - this.lines[i].p2.oy / h
    }
  }

  drawSmear(w, h) {
    return [
      this.points[3].x / w,
      1 - this.points[3].y / h,
      this.points[5].x / w,
      1 - this.points[5].y / h,
      this.points[7].x / w,
      1 - this.points[7].y / h,
    ]
  }

  addAnchor(x, y) {
    var p1 = this.addPoint(x, y, 0, 0, 50, true)
  }

  addChainLink(length) {
    var lp = this.points[this.points.length - 1]
    this.addPoint(lp.x, lp.y - length, 0, 100, length)
    this.addLine(
      this.points[this.points.length - 2],
      this.points[this.points.length - 1]
    )
  }

  setAnchor(p) {
    let anchor = this.points[0]
    const x = p.x + this.offset.x
    const y = p.y + this.offset.y
    anchor.x = x
    anchor.y = y
    anchor.ox = x
    anchor.oy = y
  }
}