// move a point
export function movePoint(p, friction = 0.9, grav) {
  if (p.fixed) {
    return
  }
  var vx = (p.x - p.ox) * friction
  var vy = (p.y - p.oy) * friction
  p.ox = p.x
  p.oy = p.y
  p.x += vx
  p.y += vy
  p.x += grav.x
  p.y += grav.y
}

// move a line's end points constrain the points to the lines length
export function constrainLine(l, resistance = 1.95) {
  const w = resistance
  var dx = l.p2.x - l.p1.x
  var dy = l.p2.y - l.p1.y
  var ll = Math.hypot(dx, dy)
  var fr = (l.len - ll) / ll / w
  dx *= fr
  dy *= fr
  if (l.p2.fixed) {
    if (!l.p1.fixed) {
      l.p1.x -= dx * w
      l.p1.y -= dy * w
    }
  } else if (l.p1.fixed) {
    if (!l.p2.fixed) {
      l.p2.x += dx * w
      l.p2.y += dy * w
    }
  } else {
    l.p1.x -= dx
    l.p1.y -= dy
    l.p2.x += dx
    l.p2.y += dy
  }
}

export function lerp(start, end, amt) {
  return (1 - amt) * start + amt * end
}

export function minmax(v, min, max) {
  return Math.min(Math.max(v, min), max)
}
