import 'styles/index.scss'

import * as twgl from 'twgl.js/dist/4.x/twgl-full'

import chroma from 'chroma-js'

import vsDraw from 'shader/main.vert'
import fsDraw from 'shader/main.frag'
import fsBlur from 'shader/blur.frag'
import fsMerge from 'shader/merge.frag'
import fsSmudge from 'shader/smudge.frag'
import fsDebug from 'shader/debug.frag'

import fsSmear from 'shader/smear.frag'
import vsSmear from 'shader/smear.vert'

import { Bristle, Vec2 } from './classes'
import { GOLDEN_ANGLE, STIFFNESS, BRISTLE_COUNT, BRISTLE_SEGMENT_COUNT } from './settings'

var vsQuad = `
 attribute vec4 position;
 attribute vec2 texcoord;
 
 varying vec2 v_texcoord;
 
 void main() {
   gl_Position = position;
   v_texcoord = texcoord;
 }
 `

var fsCopy = `
 precision mediump float;
 
 varying vec2 v_texcoord;
 
 uniform sampler2D u_texture;
 
 void main() {
   vec4 c = texture2D(u_texture, v_texcoord);
   gl_FragColor = c;
 }
 `

var $ = document.querySelector.bind(document)

var mixAmount = 0.0

let mouse = new Vec2(0,0)
let mousePrev = new Vec2(0,0)

document.addEventListener('DOMContentLoaded', () => {
var gl = $('#canvas-gl').getContext('webgl', {
  premultipliedAlpha: true,
  alpha: false
})
gl.enable(gl.BLEND);
// gl.enable(gl.DEPTH_TEST);
gl.clearDepth(1.0);
gl.blendFunc(gl.ONE, gl.ONE_MINUS_SRC_ALPHA);
// gl.blendEquation(gl.FUNC_ADD)
// gl.blendFunc(gl.ONE, gl.ONE);
var m4 = twgl.m4
const programInfoDraw = twgl.createProgramInfo(gl, [vsDraw, fsDraw])
var blurProgramInfo = twgl.createProgramInfo(gl, [vsQuad, fsBlur])
var copyProgramInfo = twgl.createProgramInfo(gl, [vsQuad, fsCopy])
var mergeProgramInfo = twgl.createProgramInfo(gl, [vsQuad, fsMerge])
var smudgeProgramInfo = twgl.createProgramInfo(gl, [vsQuad, fsSmudge])
var debugProgramInfo = twgl.createProgramInfo(gl, [vsQuad, fsDebug])
var smearProgramInfo = twgl.createProgramInfo(gl, [vsSmear, fsSmear])

// Creates a -1 to +1 quad
var quadBufferInfo = twgl.primitives.createXYQuadBufferInfo(gl)

// Creates an RGBA/UNSIGNED_BYTE texture and depth buffer framebuffer
var imgFbi = twgl.createFramebufferInfo(gl)

// Creates 2 RGBA texture + depth framebuffers
var fadeAttachments = [
  {
    format: gl.RGBA,
    min: gl.LINEAR,
    max: gl.LINEAR,
    wrap: gl.CLAMP_TO_EDGE
  },
  {
    format: gl.DEPTH_STENCIL
  }
]



var fbBrush = twgl.createFramebufferInfo(gl, fadeAttachments)
var fbDrawing = twgl.createFramebufferInfo(gl, fadeAttachments)
var fbTemp = twgl.createFramebufferInfo(gl, fadeAttachments)
var fbTemp2 = twgl.createFramebufferInfo(gl, fadeAttachments)

resizeFrameBuffers()

// progDebug(gl, fbDrawing)

const LINE_COUNT = BRISTLE_SEGMENT_COUNT * BRISTLE_COUNT
const arrays = {
  position: twgl.primitives.createAugmentedTypedArray(2, LINE_COUNT * 2),
  prev_position: twgl.primitives.createAugmentedTypedArray(2, LINE_COUNT * 2),
  color: twgl.primitives.createAugmentedTypedArray(4, LINE_COUNT * 2),
  segment_index: twgl.primitives.createAugmentedTypedArray(1, LINE_COUNT * 2),
  bristle_index: twgl.primitives.createAugmentedTypedArray(1, LINE_COUNT * 2)
}

const arraysSmear = {
  smear_position: twgl.primitives.createAugmentedTypedArray(2, LINE_COUNT * 2),
  smear_prev_position: twgl.primitives.createAugmentedTypedArray(2, LINE_COUNT * 2),
  color: twgl.primitives.createAugmentedTypedArray(4, LINE_COUNT * 2),
}

const bufferInfo = twgl.createBufferInfoFromArrays(gl, arrays)
const bufferInfoSmear = twgl.createBufferInfoFromArrays(gl, arraysSmear)

// const COLORS = {
//   yellow: chroma('yellow').gl(),
//   blue: chroma('blue').gl(),
// }

let colorIndex = 0

const COLORS = [
  chroma('#00a4fc').gl(),
  chroma('#ffdc00').gl(),
  chroma('#ff5800').gl()
]

for (let i = 0; i < BRISTLE_COUNT; ++i) {
  const bristleIndex = i / (BRISTLE_COUNT - 1)

  for (let j = 0; j < BRISTLE_SEGMENT_COUNT; ++j) {
    for (let v = 0; v < 2; ++v) {
      let segmentIndex = 1 - (j / (BRISTLE_SEGMENT_COUNT - 1))
      let alpha = segmentIndex > 0.7 ? segmentIndex / 5 : 0

      arrays.position.push(0, 0)
      arrays.prev_position.push(0, 0)

      arrays.color.push([
        alpha,
        alpha,
        alpha,
        alpha,
      ])

      arrays.segment_index.push(segmentIndex)
      arrays.bristle_index.push(bristleIndex)
    }
  }
}

console.log(arrays)

function progBrush (gl, to, from, position, isInterface = false) {
  twgl.bindFramebufferInfo(gl, to)
  gl.blendFunc(gl.ONE, gl.ONE_MINUS_SRC_ALPHA);
  // gl.blendFuncSeparate(gl.ONE, gl.ONE_MINUS_SRC_ALPHA, gl.SRC_ALPHA, gl.DST_COLOR);
  gl.blendEquation(gl.FUNC_ADD)

  if (!isInterface) {
    gl.clearColor(0, 0, 0, 0);
    gl.clear(gl.COLOR_BUFFER_BIT);
  }

  gl.useProgram(programInfoDraw.program)
  twgl.setAttribInfoBufferFromArray(gl, bufferInfo.attribs.position, position);
  twgl.setAttribInfoBufferFromArray(gl, bufferInfo.attribs.color, arrays.color);
  twgl.setAttribInfoBufferFromArray(gl, bufferInfo.attribs.segment_index, arrays.segment_index);
  twgl.setAttribInfoBufferFromArray(gl, bufferInfo.attribs.bristle_index, arrays.bristle_index);
  twgl.setBuffersAndAttributes(gl, programInfoDraw, bufferInfo)
  twgl.setUniforms(programInfoDraw, {
    u_matrix: m4.identity(),
    u_resolution: [gl.canvas.width, gl.canvas.height],
    u_isInterface: isInterface,
    u_color: COLORS[colorIndex],
    u_velocity: mouseVelocityTarget,
    u_mouse: [
      mouseTarget.x / gl.canvas.width,
      1 - (mouseTarget.y / gl.canvas.height)
    ]
  })
  if (from) {
    twgl.setUniforms(programInfoDraw, {
      u_texture: from.attachments[0],
    })
  }
  twgl.drawBufferInfo(gl, bufferInfo, gl.LINES)
  // gl.blendFunc(gl.ONE, gl.ONE_MINUS_SRC_ALPHA);
}

function progSmear (gl, to, from) {
  twgl.bindFramebufferInfo(gl, to)
  gl.blendFunc(gl.ONE, gl.ONE_MINUS_SRC_ALPHA);
  gl.blendEquation(gl.FUNC_ADD)

  gl.useProgram(smearProgramInfo.program)
  twgl.setAttribInfoBufferFromArray(gl, bufferInfoSmear.attribs.smear_position, arraysSmear.smear_position);
  twgl.setAttribInfoBufferFromArray(gl, bufferInfoSmear.attribs.smear_prev_position, arraysSmear.smear_prev_position);
  twgl.setAttribInfoBufferFromArray(gl, bufferInfoSmear.attribs.color, arrays.color);
  twgl.setBuffersAndAttributes(gl, smearProgramInfo, bufferInfoSmear)
  // twgl.setAttribInfoBufferFromArray(gl, bufferInfo.attribs.position, arrays.position);
  twgl.setUniforms(smearProgramInfo, {
    u_resolution: [gl.canvas.width, gl.canvas.height],
    u_velocity: mouseVelocityTarget,
    u_mouse: [
      mouseTarget.x / gl.canvas.width,
      1 - (mouseTarget.y / gl.canvas.height)
    ]
  })
  if (from) {
    twgl.setUniforms(smearProgramInfo, {
      u_texture: from.attachments[0],
    })
  }
  twgl.drawBufferInfo(gl, bufferInfoSmear, gl.LINE_STRIP)
  // gl.blendFunc(gl.ONE, gl.ONE_MINUS_SRC_ALPHA);
}

function progCopy (gl, to, from) {
  // gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);
  twgl.bindFramebufferInfo(gl, to)
  gl.useProgram(copyProgramInfo.program)
  twgl.setBuffersAndAttributes(gl, copyProgramInfo, quadBufferInfo)
  twgl.setUniforms(copyProgramInfo, {
    u_texture: from.attachments[0]
  })
  twgl.drawBufferInfo(gl, quadBufferInfo, gl.TRIANGLES)
}

function progMerge (gl, to, from) {
  // gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);
  twgl.bindFramebufferInfo(gl, to)
  gl.useProgram(mergeProgramInfo.program)
  twgl.setBuffersAndAttributes(gl, mergeProgramInfo, quadBufferInfo)
  twgl.setUniforms(mergeProgramInfo, {
    u_texture: from.attachments[0]
  })
  twgl.drawBufferInfo(gl, quadBufferInfo, gl.TRIANGLES)
  // gl.blendFunc(gl.ONE, gl.ONE_MINUS_SRC_ALPHA);
}

function resizeFrameBuffers () {
if (twgl.resizeCanvasToDisplaySize(gl.canvas)) {
    const w = gl.canvas.width / 1
    const h = gl.canvas.height / 1
    twgl.resizeFramebufferInfo(gl, fbBrush, fadeAttachments)
    twgl.resizeFramebufferInfo(gl, fbDrawing, fadeAttachments)
    twgl.resizeFramebufferInfo(gl, fbTemp, fadeAttachments)
    twgl.resizeFramebufferInfo(gl, fbTemp2, fadeAttachments)
    twgl.bindFramebufferInfo(gl, fbBrush)
    gl.colorMask(true, true, true, true);
    gl.clearColor(0, 0, 0, 0);
    gl.clear(gl.COLOR_BUFFER_BIT);

    twgl.bindFramebufferInfo(gl, fbTemp)
    gl.colorMask(true, true, true, true);
    gl.clearColor(0, 0, 0, 0);
    gl.clear(gl.COLOR_BUFFER_BIT);

    twgl.bindFramebufferInfo(gl, fbTemp2)
    gl.colorMask(true, true, true, true);
    gl.clearColor(0, 0, 0, 0);
    gl.clear(gl.COLOR_BUFFER_BIT);

    twgl.bindFramebufferInfo(gl, fbDrawing)
    gl.colorMask(true, true, true, true);
    gl.clearColor(0, 0, 0, 0);
    gl.clear(gl.COLOR_BUFFER_BIT);
  }
}

function render(time) {
  resizeFrameBuffers()

  twgl.bindFramebufferInfo(gl, fbTemp)
  gl.clearColor(0, 0, 0, 0);
  gl.clear(gl.COLOR_BUFFER_BIT);

  twgl.bindFramebufferInfo(gl, fbTemp2)
  gl.clearColor(0, 0, 0, 0);
  gl.clear(gl.COLOR_BUFFER_BIT);

  twgl.bindFramebufferInfo(gl, fbBrush)
  gl.clearColor(0, 0, 0, 0);
  gl.clear(gl.COLOR_BUFFER_BIT)

  if (isPressing) {
    if (hasMoved) {
      progBrush(gl, fbBrush, fbDrawing, arraysSmear.smear_position)
      progMerge(gl, fbDrawing, fbBrush)
      hasMoved = false
    }
    if (shouldSmudge) {
      progSmear(gl, fbTemp2, fbDrawing)
      progMerge(gl, fbDrawing, fbTemp2)
    }
  }

  // gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);

  progCopy(gl, null, fbDrawing)
  progBrush(gl, null, null, arrays.position, true)
  progSmear(gl, null, fbDrawing)

  requestAnimationFrame(render)
}


window.DO_SMUDGE = false

function loading() {
  doSim = runSim
}

var holdingCount = 0
let bristles = []


let hasMoved = false

let mouseVelocity = 0
let mouseVelocityTarget = 0

let count = 0

let shouldSmudge = false

function runSim() {
  const timestamp = Date.now()

  mousePrev.x = mouseTarget.x
  mousePrev.y = mouseTarget.y

  mouseTarget.x += (mouse.x - mouseTarget.x) * 0.20
  mouseTarget.y += (mouse.y - mouseTarget.y) * 0.20

  const distX = mouseTarget.x - mousePrev.x
  const distY = mouseTarget.y - mousePrev.y

  const interval = timestamp - lastMouseTimestamp;
  mouseVelocity = Math.min(((Math.sqrt(distX*distX+distY*distY)/interval) * 1000), 2) / 2

  mouseVelocityTarget += (mouseVelocity - mouseVelocityTarget) * 0.2

  hasMoved = true

  bristles.forEach((bristle, index) => {
    bristle.movePoints()
    bristle.setAnchor(mouseTarget)

    for (var i = 0; i < STIFFNESS; i++) {
      bristle.constrainLines()
    }
    bristle.drawLines(arrays.position, arraysSmear.smear_position, gl.canvas.clientWidth, gl.canvas.clientHeight, index)
  })

  count++

  if (count % 2 === 0) {
    arrays.prev_position.set(arrays.position)
    arraysSmear.smear_prev_position.set(arraysSmear.smear_position)
    shouldSmudge = true
  }

  window.requestAnimationFrame(runSim)
}

let isPressing = false


let lastMouseTimestamp = Date.now()

let mouseTarget = new Vec2(0,0)

let smearBristle

window.addEventListener('mousemove', (e) => {
  mouse.x = e.layerX
  mouse.y = e.layerY
})

window.addEventListener('mousedown', () => {
  isPressing = true
  colorIndex = (colorIndex + 1) % 3
})

window.addEventListener('mouseup', () => {
  isPressing = false
})

let width = 20
let height = 20
let lgRad = width * 1
let lgArea = Math.pow(lgRad, 2) * Math.PI

let smArea = lgArea / BRISTLE_COUNT // Area of our little circles, if they filled the space entirely
let smRad = Math.sqrt(smArea / Math.PI) // This is related to the equation area = pi r squared

let fudge = 0.9 // Fudge factor, since our circles don't actually fill up space entirely.
let adjSmDiameter = smRad * 2 * fudge

let cx = width / 2
let cy = height / 2

for (let i = 1; i <= BRISTLE_COUNT ; ++i) {
  let angle = i * GOLDEN_ANGLE
  let cumArea = i * smArea
  let spiralRad = Math.sqrt(cumArea / Math.PI)
  let x = cx + Math.cos(angle) * spiralRad - width / 2
  let y = cy + Math.sin(angle) * spiralRad - height / 2

  const distanceToCenter = 1 - spiralRad / lgRad
  const distanceX = 1 - Math.abs(x) / (width)

  const bristle = new Bristle(new Vec2(x, y), distanceToCenter, lgRad)
  bristle.createBristle()
  bristles.push(bristle)
}

smearBristle = new Bristle(new Vec2(0, 0), 0, 20)

smearBristle.createSmearBristle()

runSim()
render()

})
